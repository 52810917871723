body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.custom-dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #cecece;
  border-style: dashed;
  background-color: #ececec;
  color: #979797;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.custom-dropzone:focus {
  border-color: #2196f3;
}

.bg-gray {
  background-color: #979797;
}

.inset-box {
  //box-shadow: 3px 3px 5px -1px rgba(0, 0, 0, 0.4) inset;
  // -webkit-box-shadow: 3px 3px 5px -1px rgba(0,0,0,0.4) inset;
  // -moz-box-shadow: 3px 3px 5px -1px rgba(0,0,0,0.4) inset;
  background-color: #ececec;
  border-radius: 0.25rem;
  // border: 1px solid #cecece;
  height: 18rem;
  overflow-y: auto;
}
.inset-box::-webkit-scrollbar {
  width: 8px;
}

.inset-box::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  border-radius: 10px;
}
.custom-spinner .spinner-border {
  color: #2e008b; /* Website Purple */
  width: 2rem; /* Adjust the spinner size (radius) */
  height: 2rem; /* Ensure it's a circle */
  border-width: 0.33rem; /* Make it a bit thicker */
}

.spinner-large {
  color: #2e008b; /* Website Purple */
  width: 5rem !important;
  height: 5rem !important;
  border-width: 0.4rem !important;
}
.spinner-medium {
  color: #2e008b; /* Website Purple */
  width: 4rem !important;
  height: 4rem !important;
  border-width: 0.4rem !important;
}

.spinner-small {
  color: #2e008b; /* Website Purple */
  width: 3rem !important;
  height: 3rem !important;
  border-width: 0.4rem !important;
}
