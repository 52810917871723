.nav-tabs {
  border-bottom: 1px solid $primary;
}

.nav-tabs .nav-item .nav-link.active {
  border: 1px solid transparent;
  background: rgba(102, 51, 153, 0.1);
  border-color: $primary $primary $background;
}

.dropdown-toggle:after {
  display: inline-block;
  width: 0;
  height: 0;
  right: 5px;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
